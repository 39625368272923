import React from "react";
import { CheckCircleIcon } from '@heroicons/react/solid'

export function ThirdFeatures() {
	return (
		<div className="relative">
			<div aria-hidden="true" className="absolute hidden h-full lg:block" />
			<div className="relative lg:bg-transparent">
				<div className="mx-auto max-w-7xl px-4">
					<div className="mx-auto max-w-2xl sm:pb-24 pb-6 lg:max-w-none">
						<div className="pt-20">
							<h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl xl:text-6xl">
								Data
							</h1>
							<p className="mb-0 mt-4 text-xl text-gray-600">
								Our software is built on world-class data.
							</p>
							<p className="mt-4 mb-3 text-xl text-gray-600">
								Our data includes:
							</p>
							<span className="flex mb-1">
								<CheckCircleIcon className="h-6 w-6 mr-1" />
								<p className="mb-0 text-gray-600">
									Lawn Square Footage
								</p>
							</span>
							<span className="flex mb-1">
								<CheckCircleIcon className="h-6 w-6 mr-1" />
								<p className="mb-0 text-gray-600">
									Lot Square Footage
								</p>
							</span>
							<span className="flex mb-1">
								<CheckCircleIcon className="h-6 w-6 mr-1" />
								<p className="mb-0 text-gray-600">
									Driveway Square Footage
								</p>
							</span>
							<span className="flex mb-1">
								<CheckCircleIcon className="h-6 w-6 mr-1" />
								<p className="mb-0 text-gray-600">
									Sidewalk Square Footage
								</p>
							</span>
							<span className="flex mb-1">
								<CheckCircleIcon className="h-6 w-6 mr-1" />
								<p className="mb-0 text-gray-600">
									House Square Footage
								</p>
							</span>
							<span className="flex mb-1">
								<CheckCircleIcon className="h-6 w-6 mr-1" />
								<p className="mb-0 text-gray-600">
									House Perimeter
								</p>
							</span>
							<span className="flex mb-1">
								<CheckCircleIcon className="h-6 w-6 mr-1" />
								<p className="mb-0 text-gray-600">
									More Coming Soon!
								</p>
							</span>

						</div>
					</div>
				</div>
			</div>
			<div className="h-full w-full sm:h-full lg:absolute lg:top-0 lg:left-1/2 lg:w-1/2 xl:w-1/3 flex items-center justify-center mb-12">
				<img
					src='/images/signal-2022-09-01-183849.jpeg'
					alt="feature image"
					className="object-contain rounded-xl"
				/>
			</div>
		</div>
	)
}
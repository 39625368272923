import React,{useEffect, useState} from 'react'
import ModalVideo from 'react-modal-video'
import { Button } from './Button'

export const ReactVideoModal = () => {
  const [isOpen, setOpen] = useState(false)
  const [windowLoaded, setWindowLoaded] = useState(false)
  useEffect(() => {
    if (typeof window !== undefined) {
      setWindowLoaded(true)
    }
  }, []);

  return (  
    <React.Fragment>
      {windowLoaded && <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="UNB3DOIGZY0" onClose={() => setOpen(false)} />}
      <Button
          variant="outline" onClick={()=> setOpen(true)}
          >
          <svg
            aria-hidden="true"
            className="h-3 w-3 flex-none fill-blue-600 group-active:fill-current"
            >
            <path d="m9.997 6.91-7.583 3.447A1 1 0 0 1 1 9.447V2.553a1 1 0 0 1 1.414-.91L9.997 5.09c.782.355.782 1.465 0 1.82Z" />
          </svg>
          <span className="ml-3">Watch video</span>
        </Button> 
    </React.Fragment>
  )
}


import { NextSeo } from 'next-seo';
import React from 'react';
import SignInPage from '../components/tailwind/sign-in/sign-in-page.component';
import { CLIENT_APP_URL } from '../constants/app.constants';

const SignIn = () => (
  <>
    <NextSeo
      title="Sign In"
      canonical={`${CLIENT_APP_URL}/sign-in`}
      openGraph={{
        url: `${CLIENT_APP_URL}/sign-in`,
      }}
    />
    <SignInPage />
  </>
);

export default SignIn;

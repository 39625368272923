import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { BsLockFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMessages, signInUser } from '../../../redux/user/user.actions';
import ErrorAlert from '../alerts/error-alert.component';
import { Footer } from '../../newhome/Footer'
import { Header } from '../../newhome/Header'
import { ROBIN_WEBSITE } from '../../../constants/app.constants';

export default function SignInPage() {
  const { loading, errorMessages } = useSelector((state: any) => state.user);
  const router = useRouter();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [Password, setPassword] = useState('');
  const [isRobinOrigin, setIsRobinOrigin] = React.useState(false);

  React.useEffect(() => {
    if (router) {      
      setIsRobinOrigin(window.location.origin.includes(ROBIN_WEBSITE))
    }
  }, [router]);
  useEffect(() => {
    dispatch(setErrorMessages([]));
  }, []);

  const onSignIn = (e: any) => {
    e.preventDefault();
    dispatch(signInUser({ email, password: Password }, router));
  };

  return (
    <>
    <Header />
      
      <main className="flex items-start justify-center bg-gray-50 py-20 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            {!isRobinOrigin && 
             <img
             className="mx-auto h-12"
             style={{ width: '30%', height: '30%' }}
             src={`${typeof window != 'undefined' ? window?.location?.origin?.includes(process.env.NEXT_PUBLIC_PRO_WEBSITE as string) ? "/deeplawnlogo-pro.png" : "deeplawnlogo.png" : '' }`}
             alt="Deep Lawn Logo"
           />
           }
           
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Sign in to your account
            </h2>
            {!isRobinOrigin &&
            <p className="mb-0 mt-2 text-center text-sm text-gray-600">
            Don't have an account?{' '}
            <a href="https://deeplawn.com/#pricing" className="font-medium text-green-500 hover:text-green-600">
              Sign Up
            </a>
          </p>
          }
            
          </div>

          <form className="mt-8 space-y-6" onSubmit={onSignIn}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </div>
            {!isRobinOrigin &&
            
            <div className="flex items-center justify-between">
              <div className="text-sm">
                <Link href="/forgot-password" className="font-medium text-green-500 hover:text-green-600">
                  Forgot your password?
                </Link>
              </div>
            </div>
            }

            {errorMessages && errorMessages.length > 0 && (
              <ErrorAlert errorMessages={errorMessages} />
            )}

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <BsLockFill />
                </span>
                Sign in
              </button>
            </div>
          </form>
        </div>
      </main>
      {!isRobinOrigin ? <Footer />:null}
    </>
  );
}

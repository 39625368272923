import Image from 'next/image'
import React from 'react'
import { Button } from './Button'
import { Container } from './Container'
import backgroundImage from '../../public/images/background_call_to_action.jpg'

export function CallToAction() {
  return (
    <section
      id="get-started-today"
      className="relative overflow-hidden py-32 bg-blue-600"
    >
      <img
        src='/images/background-call-to-action.jpg'
        alt=""
        className='absolute -top-96'
        style={{
          color: 'transparent',
          width: '2347px',
          height: '1244px'
        }}
      />
      <Container className="relative">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
          Take your business where your customers are - online
          </h2>
          {/* <p className="mt-4 text-lg tracking-tight text-white">
            Take your business where your customers are - online
          </p> */}
          <Button href="/pricing" color="white" className="mt-10">
            Sign Up Today
          </Button>
        </div>
      </Container>
    </section>
  )
}

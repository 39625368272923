import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'

import { CallToAction } from '../components/newhome/CallToAction'
import { Faqs } from '../components/newhome/Faqs'
import { Footer } from '../components/newhome/Footer'
import { Header } from '../components/newhome/Header'
import { Hero } from '../components/newhome/Hero'
import { PricingNew } from '../components/newhome/Pricing'
import { PrimaryFeatures } from '../components/newhome/PrimaryFeatures'
import { SecondaryFeatures } from '../components/newhome/SecondaryFeatures'
import { Testimonials } from '../components/newhome/Testimonials'
import { ThirdFeatures } from '../components/newhome/ThirdFeature'
import { ROBIN_WEBSITE } from '../constants/app.constants'
import SignIn from './sign-in'
import { isPro } from '../redux/widget/widget.selectors'

export default function Home() {
  const router = useRouter();
  const [isRobinOrigin, setIsRobinOrigin] = React.useState<any>(null);
  const [proOrigin, setProOrigin] = React.useState<any>(null);

  React.useEffect(() => {
    if (router) {
      setIsRobinOrigin(window.location.origin.includes(ROBIN_WEBSITE!))
      setProOrigin(isPro(window))
    }
  }, [router]);
  
  if ((isRobinOrigin || proOrigin) === null) return <div></div>
  if (isRobinOrigin || proOrigin) {
    return <SignIn />
  }
  return <SignIn />
  return (
    <>
      <Head>
        <title>Home - Deep Lawn | E-Commerce tools for lawn care Companies</title>
        <meta
          name="description"
          content="The ultimate sales tool for lawn care and pest control companies"
        />
        <meta name="facebook-domain-verification" content="cr67r4jf33xluntlbzxytikxw4ggxf" />

        {/* google analytics code */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11201156007"></script>

        <script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-11201156007');
          `,
          }}
        />

        {/* Meta Pixel Code */}
        <script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '628587065978736');
            fbq('track', 'PageView');
          `
          }}
        />

        <noscript><img height="1" width="1"
          src="https://www.facebook.com/tr?id=628587065978736&ev=PageView&noscript=1"
        /></noscript>

      </Head>
      <Header />
      <main>
        <Hero />
        <PrimaryFeatures />
        <SecondaryFeatures />
        <ThirdFeatures />
        <CallToAction />
        {/* <Testimonials /> */}
        <br />
        <br />
        <br />
        <PricingNew />
        <Faqs />
      </main>
      <Footer />
    </>
  )
}
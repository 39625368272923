import { useEffect, useState } from 'react'
import Image from 'next/image'
import { Tab } from '@headlessui/react'
import clsx from 'clsx'
import { Container } from './Container'
import React from 'react'

const features = [
  {
    title: 'Measurements',
    description:
      "Generate measurements in seconds from desktop or mobile.",
    image: '/images/screenshots/screenshot1.png',
  },
  {
    title: 'Imagery',
    description:
      "Choose from Standard imagery or High Definition imagery (for brand new houses, developments, fast growing areas)",
    image: '/images/screenshots/screenshot2.png',
  },
  {
    title: 'Quoting',
    description:
      "Our software will instantly generate pricing for your services based on your pricing tables. Easily send quotes to your clients while on the phone or from your device in the field.",
    image: '/images/screenshots/screenshot3.png',
  },
  {
    title: 'Geopricing',
    description:
      'Add the zipcodes that you service or draw custom service areas. Easily add surcharges or offer unique services per area. Know the number of properties in each region.',
    image: '/images/screenshots/screenshot4.png',
  },
]

export function PrimaryFeatures() {
  let [tabOrientation, setTabOrientation] = useState('horizontal')
  let [isLarge, setIsLarge] = useState(false)

  useEffect(() => {
    let lgMediaQuery = window.matchMedia('(min-width: 1024px)')

    if (lgMediaQuery.matches) {
      setIsLarge(true)
    } else {
      setIsLarge(false)
    }

    function onMediaQueryChange({ matches }) {
      setTabOrientation(matches ? 'vertical' : 'horizontal')
    }

    onMediaQueryChange(lgMediaQuery)
    lgMediaQuery.addEventListener('change', onMediaQueryChange)

    return () => {
      lgMediaQuery.removeEventListener('change', onMediaQueryChange)
    }
  }, [])

  return (
    <section
      id="features"
      aria-label="Features for running your books"
      className="relative overflow-hidden bg-blue-600 pt-20 pb-28 sm:py-32"
    >
      <div className='absolute'>
        <Image
          src='/images/background-features.jpg'
          alt=""
          width={2245}
          height={1636}
          unoptimized
        />
      </div>
      <Container className="relative">
        <div className="max-w-4xl">
          <h2 className="text-3xl text-white sm:text-4xl md:text-5xl">
            Everything you need for Measurements, Quoting and Selling on your website
          </h2>
          {/* <p className="mt-6 text-lg tracking-tight text-blue-100">
            For Measurements, Quoting and Selling on your website
          </p> */}
        </div>
        <Tab.Group
          as="div"
          className="mt-16 grid grid-cols-1 items-center pt-10 gap-y-6"
          vertical={tabOrientation === 'vertical'}
        >
          {({ selectedIndex }) => (
            <>
              <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                <Tab.List className="relative z-10 flex gap-x-4 whitespace-nowrap mx-auto px-0">
                  {features.map((feature, featureIndex) => (
                    <div
                      key={feature.title}
                      className={clsx(
                        'relative rounded-full py-1 px-4',
                        selectedIndex === featureIndex
                          ? 'bg-white lg:ring-1 lg:ring-inset'
                          : 'hover:opacity-70 lg:hover:opacity-70'
                      )
                      }
                      style={(selectedIndex === featureIndex) && (isLarge) ? {
                        backgroundColor: 'rgba(255,255,255,0.1)'
                      } : {}}
                    >
                      <h3>
                        <Tab
                          className={clsx(
                            'text-lg focus:outline-none',
                            selectedIndex === featureIndex
                              ? 'text-blue-600 lg:text-white'
                              : 'text-blue-100 hover:text-white lg:text-white'
                          )}
                        >
                          <span className="absolute inset-0 rounded-full" />
                          {feature.title}
                        </Tab>
                      </h3>
                      <p
                        className={clsx(
                          'mt-2 hidden text-sm',
                          selectedIndex === featureIndex
                            ? 'text-white'
                            : 'text-blue-100 group-hover:text-white'
                        )}
                      >
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels className="">
                {features.map((feature) => (
                  <Tab.Panel key={feature.title} unmount={false}>
                    <div className="relative sm:px-6">
                      <div className="absolute -inset-x-4 -top-24 -bottom-16 ring-inset sm:inset-x-0 sm:rounded-t-xl"
                        style={{
                          background: 'rgba(255,255,255,0.1)',
                          outlineStyle: 'solid',
                          outlineColor: 'rgba(255,255,255,0.1)'
                        }}
                      />
                      <p className="mb-0 relative mx-auto max-w-2xl text-base text-white sm:text-center">
                        {feature.description}
                      </p>
                    </div>
                    <div className="mt-10 overflow-hidden rounded-xl bg-gray-50 shadow-xl sm:w-auto">
                        <img
                          src={feature.image}
                          alt=""
                          className='w-full'
                        />
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </>
          )}
        </Tab.Group>
      </Container>
    </section>
  )
}
